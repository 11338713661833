import React from 'react'
import './style.scss'

import HeroDetail from './HeroDetail'
import Map from './Map'
import ParallaxAbout from './ParallaxAbout'
// import ReactScroll from './ReactScroll'

function ACFCustom(props) {
  // Chain Animation
  const delay = props.inheritedDelay

  // Images
  const images = props.asset?.filter(obj => obj.fieldGroupName === 'page_Pagebuilder_Sections_Content_Block_Custom_Asset_Image')
    .map((node) => {
      return node.assetImg?.localFile.childImageSharp.gatsbyImageData
    })

  // Files
  const files = props.asset?.filter(obj => obj.fieldGroupName === 'page_Pagebuilder_Sections_Content_Block_Custom_Asset_File')
    .map((node) => {
      return node.assetFile
    })

  // Fields
  const fields = props.asset?.filter(obj => obj.fieldGroupName === 'page_Pagebuilder_Sections_Content_Block_Custom_Asset_Field')
    .map((node) => {
      return node.assetField
    })

  // WYSIWYGS
  const wysiwygs = props.asset?.filter(obj => obj.fieldGroupName === 'page_Pagebuilder_Sections_Content_Block_Custom_Asset_Wysiwyg')
    .map((node) => {
      return node.assetWysiwyg
    })

  // Anchors
  const anchors = props.asset?.filter(obj => obj.fieldGroupName === 'page_Pagebuilder_Sections_Content_Block_Custom_Asset_Wysiwyg')
    .map((node) => {
      return node.assetId
    })

  // Maps
  const maps = props.asset?.filter(obj => obj.fieldGroupName === 'page_Pagebuilder_Sections_Content_Block_Custom_Asset_Map')
    .map((node) => {
      return node.assetMap
    })

  // Gallery
  const gallery = props.asset?.filter(obj => obj.fieldGroupName === 'page_Pagebuilder_Sections_Content_Block_Custom_Asset_Gallery')
    .map((node) => {
      return node
    })


  if (props.fieldGroupName === 'page_Pagebuilder_Sections_Content_Block_Custom' && props.anchor === 'custom-home-detail') {
    return (
      <HeroDetail delay={delay} images={images} files={files} fields={fields} wysiwygs={wysiwygs} maps={maps} />
    )
  }

  if (props.fieldGroupName === 'page_Pagebuilder_Sections_Content_Block_Custom' && props.anchor === 'custom-map') {
    return (
      <Map delay={delay} images={images} files={files} fields={fields} wysiwygs={wysiwygs} maps={maps} />
    )
  }

  if (props.fieldGroupName === 'page_Pagebuilder_Sections_Content_Block_Custom' && props.anchor === 'parallax-about') {
    return (
      <ParallaxAbout images={images} wysiwygs={wysiwygs} gallery={gallery} anchors={anchors} />
    )
  }

  // if (props.fieldGroupName === 'page_Pagebuilder_Sections_Content_Block_Custom' && props.anchor === 'react-scroll') {
  // 	return (
  // 		<ReactScroll images={images} wysiwygs={wysiwygs} gallery={gallery}/>
  // 	)
  // }

  // if (props.fieldGroupName === 'page_Pagebuilder_Sections_Content_Block_Custom' && props.anchor === 'custom-projects') {
  // 	return (
  // 		<Projects images={images} wysiwygs={wysiwygs} gallery={gallery}/>
  // 	)
  // }

  return false
}

export default ACFCustom