import React, { useEffect, useRef } from 'react'
import './style.scss'

//import { GatsbyImage } from 'gatsby-plugin-image'
import Intro from '../../../../../../animations/Intro'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Parallax } from 'react-scroll-parallax';
import { useInView } from 'react-intersection-observer'
import Slider from 'react-slick';

function CustomBlock({images, random, i}) {

  const [io, ioInView] = useInView({ triggerOnce: true })
  const [ioSlider, ioSliderInView] = useInView({ triggerOnce: false })

  const sliderRef = useRef()

  useEffect(() => {
    if(ioSliderInView)
      sliderRef.current.slickPlay()
    else
      sliderRef.current.slickPause()
  }, [ioSliderInView])

  // Slider settings
const sliderSettings = {
	dots: true,
	arrows: false,
	infinite: true,
	speed: 800,
	//pauseOnFocus: true,
  pauseOnHover: true,
	 autoplay: true,
	autoplaySpeed: 4000,
	slidesToShow: 1,
	slidesToScroll: 1,
	accessibility: false,
	//focusOnSelect: false,
	adaptiveHeight: false,
	centerMode: false,
	variableWidth: false,
  appendDots: dots =>
  <div className='dots'>
  <ul>
    {dots}
  </ul>
</div>,
  customPaging: i => (
    <span></span>
  )
}

  const speed = random ? 10 + random : 0

  return (
    <div ref={io} style={{ zIndex: 100 - i }} className='span-12-mobile img-parallax'>
      <div className='shadow'>
        <div className='container'>
          <Parallax speed={speed}>
            <Intro visible={ioInView} in={{ fadeBottom: 1200 }} delayIn={550} mounted={true} stay={true} className={``}>
                <div ref={ioSlider} className="slider with-aspect">
                  <Slider ref={sliderRef}	{...sliderSettings}>
                      {images?.map((image, i) => {
                        return (
                          <div key={i} className='single-img'>
                            <GatsbyImage image={image?.localFile?.childImageSharp?.gatsbyImageData} alt={"parallax-img"}/>
                          </div>
                        )
                      })}
                    </Slider>
                </div>
            </Intro>
          </Parallax>
        </div>
      </div>
    </div>
  )
}

export default CustomBlock