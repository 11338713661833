import React from 'react'
import './style.scss'
import AcfWysiwyg from '../../../WYSIWYG'
import { Parallax } from 'react-scroll-parallax';
import { Link } from 'gatsby';
import Intro from '../../../../../../animations/Intro';
import { useInView } from 'react-intersection-observer'

function CustomBlock(props) {
  const [io, ioInView] = useInView({ triggerOnce: true })

    const speed = props.random ? 10 + props.random : 0

  return (

    <div ref={io} style={{ zIndex: 100 - props.i }} className={`parallax-copy span-12-mobile flex ac-center ai-center copy`}>
      <Parallax speed={speed}>
        {props.wysiwygs ?
          <div  style={{ flexWrap: 'wrap' }} className='description padd w-100'>
            <AcfWysiwyg wysiwyg={props.wysiwygs} />
            <Intro visible={ioInView} in={{ fade: 400 }} delayIn={750} mounted={true} stay={true} className={``}>
              <Link className="btn arrow" to={`/realizacje/${props.anchor}`}>
                <span>Zobacz</span>
              </Link>
            </Intro>
          </div>
          : null}
      </Parallax>
    </div>
  )
}

export default CustomBlock