import React, { useEffect, useState } from 'react'
import './style.scss'
import Slider from './Slider'
import Copy from './Copy'

function CustomBlock(props) {

  const [width, setWidth] = useState()

  useEffect(() => {
    if (typeof window !== 'undefined' && window.innerWidth)
      setWidth(window.innerWidth)
  }, [])

  let random = width <= 768 ? 0 : 1;

  const sliders = props.gallery.map((item, i) => {
    random = random ? Math.floor(Math.random() * 10) + 1 : random;
    return (

      <Slider random={random} key={'img' + i} i={i} images={item.assetImgArray} reverseOrder={i % 2 === 0 ? false : true}></Slider>
    )
  })

  const wysiwygs = props.wysiwygs.map((item, i) => {
    random = random ? Math.floor(Math.random() * 10) + 1 : random;
    return (
      <Copy random={random} key={'copy' + i} i={i} anchor={props?.anchors?.[i]} wysiwygs={item} reverseOrder={i % 2 === 0 ? false : true}></Copy>
    )
  })



  const sections = props.gallery.map((item, i) => {
    if (width > 768)
      return (
        i % 2 === 0 ?
          <div key={'reverse-'+i} className="flex-section" >
            {sliders[i]}
            {wysiwygs[i]}
          </div>
          :
          <div key={i} className="flex-section" >
            {wysiwygs[i]}
            {sliders[i]}
          </div>
      )
    else
      return (
        <div className="flex-section" key={i}>
          {wysiwygs[i]}
          {sliders[i]}
        </div>
      )
  })


  return (
    <div className='parallax-block-sections padd-bottom-4 padd-top-4'>
      {sections}
      <div className='bg-1'></div>
    </div>
  )
}

export default CustomBlock